import Victual from './victual'
import Recipe from './recipe'

export default interface ShoppingList {
  items: Array<Victual | Recipe>
}

export function isVictual (item: Victual | Recipe): item is Victual {
  return 'amountInGrams' in item
}

export function isRecipe (item: Victual | Recipe): item is Recipe {
  return 'servingSize' in item
}

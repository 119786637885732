import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_APIKEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTHDOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASEURL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.VUE_APP_FIREBASE_APPID
}

firebase.initializeApp(firebaseConfig)
const db = firebase.firestore()
const auth = firebase.auth()

// db collections
const victualsCollection = db.collection('victuals')
const victualsArchiveCollection = db.collection('victuals_archive')
const recipesCollection = db.collection('recipes')
const recipesArchiveCollection = db.collection('recipes_archive')
const shoppingLists = db.collection('shoppinglists')
const counterCollection = db.collection('counter')
const userGroupsCollection = db.collection('user_groups')
const userCollection = db.collection('users')
const feedbackCollection = db.collection('feedback')
const victualsTracingCollection = db.collection('victuals_tracing')
const activityCollection = db.collection('logs_activity')

export {
  db,
  auth,
  victualsCollection,
  victualsArchiveCollection,
  victualsTracingCollection,
  recipesCollection,
  recipesArchiveCollection,
  counterCollection,
  shoppingLists,
  userGroupsCollection,
  userCollection,
  feedbackCollection,
  activityCollection
}

import { activityCollection } from '@/firebaseConfig'
import { Activity } from '../model/activity'
import { ActivityApi } from './api'

class ActivityFirebaseApi implements ActivityApi {
  async getLatest (limit: number): Promise<Activity[]> {
    if (limit < 1 || limit > 50) {
      limit = 10
    }

    const snaps = await activityCollection
      .orderBy('createdAt', 'desc')
      .limit(limit)
      .get()

    const activities: Activity[] = snaps.docs.map(doc => ({
      createdAt: doc.data().createdAt.toDate(),
      triggeredByUserId: doc.data().triggeredByUserId,
      eventType: doc.data().eventType,
      relatedDocId: doc.data().relatedDocId,
      docOwnedByGroupId: doc.data().docOwnedByGroupId,
      info: doc.data().info,
      diff: doc.data().diff
    }))

    return activities
  }
}

const activityFirebaseApi = new ActivityFirebaseApi()
export { activityFirebaseApi }

import firebase from 'firebase'
import { shoppingLists, auth } from '@/firebaseConfig'

import ShoppingListApi from './api'
import ShoppingList from '../model/shoppinglist'
import Recipe from '../model/recipe'
import Victual from '../model/victual'

const serverTimestamp = (): firebase.firestore.FieldValue => firebase.firestore.FieldValue.serverTimestamp()
const isVictual = (item: Victual | Recipe): item is Victual => 'amountInGrams' in item
const isRecipe = (item: Victual | Recipe): item is Recipe => 'servingSize' in item

interface ShoppinglistItemVictualFire {
  type: 'victual'
  id: string
  name: string
  amountInGrams: number
  pricePer100g: number
  supermarketDepartment?: string
  units: Array<{
    name: string
    oneUnitEqualsGrams: number
  }>
}
interface ShoppinglistItemRecipeFire {
  type: 'recipe'
  id: string
  name: string
  servingSize: number
}
type ShoppinglistItemFire = ShoppinglistItemVictualFire | ShoppinglistItemRecipeFire
interface ShoppinglistFire {
  updatedAt: firebase.firestore.FieldValue
  items: ShoppinglistItemFire[]
}
const isVictualFire = (item: ShoppinglistItemFire): item is ShoppinglistItemVictualFire => item.type === 'victual'
const isRecipeFire = (item: ShoppinglistItemFire): item is ShoppinglistItemRecipeFire => item.type === 'recipe'

const shoppingListConverter: firebase.firestore.FirestoreDataConverter<ShoppingList> = {
  toFirestore: function (shoppingList: ShoppingList): ShoppinglistFire {
    const victuals: ShoppinglistItemVictualFire[] = shoppingList.items
      .filter(item => isVictual(item))
      .map(item => {
        const itemVictual = item as Victual
        return {
          type: 'victual',
          id: itemVictual.id,
          name: itemVictual.name,
          amountInGrams: itemVictual.amountInGrams,
          pricePer100g: itemVictual.pricePer100g,
          supermarketDepartment: itemVictual.supermarketDepartment,
          units: itemVictual.units
        }
      })

    const recipes: ShoppinglistItemRecipeFire[] = shoppingList.items
      .filter(item => isRecipe(item))
      .map(
        item => {
          const itemRecipe = item as Recipe
          return {
            type: 'recipe',
            id: itemRecipe.id,
            name: itemRecipe.name,
            servingSize: itemRecipe.servingSize
          }
        })

    shoppingList.items
      .filter(item => !isVictual(item) && !isRecipe(item))
      .forEach(item =>
        // eslint-disable-next-line no-console
        console.error(
          'Unable to store unknown type to shopping list: ' +
            JSON.stringify(item)
        )
      )

    return {
      updatedAt: serverTimestamp(),
      items: [...victuals, ...recipes]
    }
  },

  fromFirestore: function (snapshot, options): ShoppingList {
    const fireData = snapshot.data(options) as ShoppinglistFire
    if (fireData.items.length === 0) {
      return {
        items: []
      }
    }

    const recipes: Recipe[] = fireData.items
      .filter(item => isRecipeFire(item))
      .map(
        item => {
          const recipeFire = item as ShoppinglistItemRecipeFire
          return {
            id: recipeFire.id,
            name: recipeFire.name,
            servingSize: +recipeFire.servingSize
          }
        }
      )

    const victuals: Victual[] = fireData.items
      .filter(item => isVictualFire(item))
      .map(
        item => {
          const victualFire = item as ShoppinglistItemVictualFire
          return {
            id: victualFire.id,
            name: victualFire.name,
            amountInGrams: +victualFire.amountInGrams,
            pricePer100g: +victualFire.pricePer100g,
            supermarketDepartment: victualFire.supermarketDepartment,
            units: victualFire.units
          }
        }
      )

    return {
      items: [...victuals, ...recipes]
    }
  }
}

class ShoppingListFirebaseApi implements ShoppingListApi {
  async save (shoppingList: ShoppingList): Promise<void> {
    const userId = auth.currentUser?.uid
    return await shoppingLists
      .doc(userId)
      .withConverter(shoppingListConverter)
      .set(shoppingList)
  }

  async get (): Promise<ShoppingList> {
    const userId = auth.currentUser?.uid
    const doc = await shoppingLists
      .doc(userId)
      .withConverter(shoppingListConverter)
      .get()

    if (!doc.exists) {
      const empty: ShoppingList = { items: [] }
      return empty
    }

    return doc.data() as ShoppingList
  }

  async delete (): Promise<void> {
    const userId = auth.currentUser?.uid

    return await shoppingLists
      .doc(userId)
      .withConverter(shoppingListConverter)
      .delete()
  }
}

const shoppingListFirebaseApi = new ShoppingListFirebaseApi()
export { shoppingListFirebaseApi }

































import Vue from 'vue'
import Component from 'vue-class-component'

import { userStore } from '@/auth/state'
import Login from '@/auth/views/Login.vue'
import ActivityCard from '@/activity/components/ActivityCard.vue'

@Component({ components: { Login, ActivityCard } })
export default class RecipePrint extends Vue {
  carouselWidth = '900px'

  get isSignedIn (): boolean {
    return userStore.state.currentUser.id !== '0'
  }
}





































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import * as fire from '../../firebaseConfig'
import { userFirebaseApi } from '../api/firebaseApi'

@Component({})
export default class Register extends Vue {
  showPW1 = false
  showPW2 = false
  error = ''
  dialog = false
  valid = false

  registerForm = {
    email: '',
    password1: '',
    password2: '',
    acceptAgbAndDataPrivacy: false
  }

  allowedDomains: string[] = []

  async mounted (): Promise<void> {
    this.allowedDomains = await userFirebaseApi.getGroupEMails()
  }

  submit (): void {
    if (!this.validate()) {
      return
    }
    
    if (this.registerForm.password1 !== this.registerForm.password2) {
      this.error = this.$root.$t('auth.password_not_same') as string
      return
    }

    if (!this.registerForm.acceptAgbAndDataPrivacy) {
      this.error = this.$root.$t('auth.agb_data_privacy_accept_missing') as string
      return
    }

    fire.auth
      .createUserWithEmailAndPassword(
        this.registerForm.email,
        this.registerForm.password1
      )
      .then(() => {
        this.sendUserMail()
      })
      .catch(err => {
        this.error = err.message
      })
  }

  sendUserMail (): void {
    const user = fire.auth.currentUser
    if (user == null) {
      return
    }
    user
      .sendEmailVerification()
      .then(() => {
        this.dialog = true
      })
      .catch(err => {
        this.error = err.message
      })
  }

  async cancel (): Promise<void> {
    this.error = ''
    this.registerForm.email = ''
    this.registerForm.password1 = ''
    this.registerForm.password2 = ''
    await this.$router.push('/')
  }

  async okDialogAgree (): Promise<void> {
    this.dialog = false
    await this.$router.push('/')
  }

  validate (): boolean {
    return (this.$refs.form as Vue & { validate: () => boolean }).validate()
  }

  passwordRules = [
    (v: string): true | string => 
      (v !== undefined && v !== null) || this.$root.$t('validation.required') as string,
    (v: string): true | string => v.length >= 6 || this.$root.$t('validation.minChars', { number: 6 }) as string
  ]

  emailRules = [
    (v: string): true | string => 
      (v !== undefined && v !== null) || this.$root.$t('validation.required') as string,
    (v: string): true | string =>
      /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,8})+$/.test(v) || this.$root.$t('validation.email') as string,
    (v: string): true | string => this.isPartOfGroup(v) || this.$root.$t('auth.nogroup_validation') as string
  ]

  isPartOfGroup (email: string): boolean {
    const index = email.indexOf('@')
    const domain = email.substring(index)

    return this.allowedDomains.includes(domain)
  }
}

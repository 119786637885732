import ShoppingList from './model/shoppinglist'
import { SHOPPINGLIST_ADD_VICTUAL, SHOPPINGLIST_ADD_RECIPE } from './events'
import * as VictualDomain from '../victual/model/victual'
import Victual from './model/victual'
import { Recipe as RecipeDomain } from '../recipe/model/recipe'
import Recipe from './model/recipe'
import ShoppingListApi from './api/api'
import { shoppingListFirebaseApi } from './api/firebaseApi'
import * as fire from '@/firebaseConfig'

const SYNC_TIME_MS = 2000

class State {
  // readonly because otherwise no update to using components when set to new object
  private readonly _shoppingList: ShoppingList = {
    items: []
  }

  // should never refer to the real _shoppingList, otherwise changes reflect and no save to firebase happens
  private _shoppingListBeforeSync: ShoppingList = this.deepClone(
    this._shoppingList
  )

  private readonly shoppingListApi: ShoppingListApi = shoppingListFirebaseApi

  constructor () {
    window.addEventListener(
      SHOPPINGLIST_ADD_VICTUAL,
      this.onVictualAddedEvent as EventListener
    )
    window.addEventListener(
      SHOPPINGLIST_ADD_RECIPE,
      this.onRecipeAddedEvent as EventListener
    )

    setInterval(async () => {
      await this.saveOnChange()
    }, SYNC_TIME_MS)

    fire.auth.onAuthStateChanged(async user => {
      if (user !== null) {
        await this.fetchState()
      }
    })
    setTimeout(async () => {
      if (fire.auth.currentUser !== null) {
        await this.fetchState()
      }
    }, 0)
  }

  get shoppingList (): ShoppingList {
    return this._shoppingList
  }

  private isInSync (): boolean {
    const sl = JSON.stringify(this._shoppingList)
    const slBeforeSync = JSON.stringify(this._shoppingListBeforeSync)
    return sl === slBeforeSync
  }

  private readonly saveOnChange = async (): Promise<void> => {
    if (this.isInSync()) {
      return
    }

    await this.shoppingListApi.save(this.shoppingList)
    this._shoppingListBeforeSync = this.deepClone(this._shoppingList)
  }

  private async fetchState (): Promise<void> {
    this._shoppingList.items = []
    const savedState = await this.shoppingListApi.get()

    savedState.items.forEach(item => this._shoppingList.items.push(item))
    this._shoppingListBeforeSync = this.deepClone(savedState)
  }

  private readonly onVictualAddedEvent = (event: CustomEvent<VictualDomain.default>): void => {
    const victual: Victual = {
      id: event.detail.id,
      name: event.detail.name,
      supermarketDepartment: event.detail.supermarketDepartment,
      amountInGrams: event.detail.defaultAmountInGrams,
      pricePer100g: event.detail.pricePer100g,
      units: event.detail.units
    }

    this._shoppingList.items.push(victual)
  }

  private readonly onRecipeAddedEvent = (event: CustomEvent<RecipeDomain>): void => {
    const recipe: Recipe = {
      id: event.detail.id,
      name: event.detail.name,
      servingSize: event.detail.servingSize
    }

    this._shoppingList.items.push(recipe)
  }

  private deepClone (obj: ShoppingList): ShoppingList {
    return JSON.parse(JSON.stringify(obj))
  }
}

export default new State()

import * as fire from '../firebaseConfig'
import { Group, User } from './api/api'
import { userFirebaseApi as fireApi } from './api/firebaseApi'

interface State {
  currentUser: User
  currentGroup?: Group
  isLoggedIn: boolean
  isAdmin: boolean
  groups: Group[]
}

class Store {
  private readonly emptyUser: User = {
    id: '0',
    email: 'unknown',
    group: 'unknown'
  }

  readonly state: State = {
    currentUser: this.emptyUser,
    isLoggedIn: false,
    isAdmin: false,
    groups: []
  }

  constructor () {
    fire.auth.onAuthStateChanged(this.onAuthStateChanged)

    const userFromSession = sessionStorage.getItem('currentUser')
    if (userFromSession != null && userFromSession.length > 0) {
      this.state.currentUser = JSON.parse(userFromSession)
    }
  }

  private readonly onAuthStateChanged = async (): Promise<void> => {
    const user = fire.auth.currentUser
    if (user === null) {
      this.state.currentUser = this.emptyUser
      this.state.isLoggedIn = false
      this.state.isAdmin = false

      sessionStorage.setItem('currentUser', '')
      return
    }

    await this.loadGroups()
    this.state.currentUser = await fireApi.getUser(user.uid)
    const currentGroupId = this.state.currentUser.group
    this.state.currentGroup = this.state.groups.find(g => g.id === currentGroupId)
    this.state.isLoggedIn = true
    this.state.isAdmin = this.state.currentUser.group === 'admin'

    sessionStorage.setItem('currentUser', JSON.stringify(this.state.currentUser))
  }

  private async loadGroups (): Promise<void> {
    if (this.state.groups.length > 0) {
      return
    }
    this.state.groups = await fireApi.getAllGroups()
  }

  groupName (id: string): string {
    const found = this.state.groups?.find(g => g.id === id)
    return found?.name ?? ''
  }
}

const userStore = new Store()
export { userStore }

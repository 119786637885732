export interface Activity {
  createdAt: Date
  triggeredByUserId: string
  eventType: EventType
  relatedDocId: string
  docOwnedByGroupId: string
  info: string
  diff: Diff[]
}

export enum EventType {
  VICTUAL_CREATE = 'victual-create',
  VICTUAL_UPDATE = 'victual-update',
  VICTUAL_DELETE = 'victual-delete',
  RECIPE_CREATE = 'recipe-create',
  RECIPE_UPDATE = 'recipe-update',
  RECIPE_DELETE = 'recipe-delete'
}

export interface Diff {
  property: string
  before: object
  after: object
}

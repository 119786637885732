import { userCollection, userGroupsCollection } from '../../firebaseConfig'
import UserApi, { Group, User } from './api'

class UserFirebaseApi implements UserApi {
  async getGroupEMails (): Promise<string[]> {
    const snap = await userGroupsCollection.orderBy('emails', 'asc').get()
    let emails: string[] = []
    snap.forEach(doc => {
      const group = doc.data() as Group
      emails = emails.concat(group.emails)
    })

    return emails
  }

  async getAllGroups (): Promise<Group[]> {
    const snap = await userGroupsCollection.orderBy('name', 'asc').get()
    const groups: Group[] = []
    snap.forEach(doc => {
      const item = doc.data() as Group
      groups.push({
        id: doc.id,
        emails: item.emails,
        name: item.name
      })
    })

    return groups
  }

  async getUser (uid: string): Promise<User> {
    const doc = await userCollection.doc(uid).get()
    if (!doc.exists) {
      return await Promise.reject(new Error('Unable to find user with id: ' + uid))
    }

    const user = doc.data() as User
    user.id = uid
    return user
  }
}

const userFirebaseApi = new UserFirebaseApi()
export { userFirebaseApi }

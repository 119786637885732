



































































import Vue from 'vue'
import Component from 'vue-class-component'

import ActionIcon from '@/shared/components/ActionIcon.vue'
import * as ShoppinglistEvent from './events'
import ShoppingList, {
  isVictual as doIsVictual,
  isRecipe as doIsRecipe
} from './model/shoppinglist'

import state from './state'

@Component({
  components: { ActionIcon }
})
export default class ShoppinglistSidebar extends Vue {
  isVisible = false
  shoppinglist: ShoppingList = state.shoppingList

  isVictual = doIsVictual
  isRecipe = doIsRecipe

  mounted (): void {
    window.addEventListener(ShoppinglistEvent.SHOPPINGLIST_SIDEBAR_OPEN, () => {
      this.isVisible = true
    })

    window.addEventListener(
      ShoppinglistEvent.SHOPPINGLIST_SIDEBAR_CLOSE,
      () => {
        this.isVisible = false
      }
    )
  }

  onClose (): void {
    this.isVisible = false
  }
}


































































import Vue from 'vue'
import Component from 'vue-class-component'
import * as fire from '../../firebaseConfig'

@Component({})
export default class Login extends Vue {
  showPW = false
  error = ''

  loginForm = {
    email: '',
    password: ''
  }

  submit (): void {
    fire.auth
      .signInWithEmailAndPassword(this.loginForm.email, this.loginForm.password)
      .then(async () => {
        const user = fire.auth.currentUser
        if (user?.emailVerified === undefined) {
          this.error = this.$root.$t('auth.email_not_verified') as string
          return
        }

        await this.$router.push('/')
      })
      .catch(err => {
        this.error = err.message
      })
  }
  
  async cancel (): Promise<void> {
    this.loginForm.email = ''
    this.loginForm.password = ''
    this.error = ''
    await this.$router.push('/')
  }
}

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import * as fire from './firebaseConfig'

Vue.config.productionTip = false
let app: Vue | undefined

fire.auth.onAuthStateChanged(() => {
  if (app === undefined) {
    app = new Vue({
      router,
      vuetify,
      i18n,
      render: h => h(App)
    }).$mount('#app')
  }
})





















import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component({})
export default class Action extends Vue {
  @Prop(String)
  icon!: string

  @Prop(String)
  tooltip!: string

  @Prop({ default: false })
  disabled!: boolean

  onClick (event: Event): void {
    this.$emit('click', event)
  }
}

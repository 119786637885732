


































import Vue from 'vue'
import Component from 'vue-class-component'

import { SHOPPINGLIST_SIDEBAR_OPEN } from './events'
import state from './state'
import { Watch } from 'vue-property-decorator'

@Component({})
export default class ShoppinglistButton extends Vue {
  shoppinglist = state.shoppingList
  itemsInBag = this.shoppinglist.items.length

  onClick (): void {
    const event = new Event(SHOPPINGLIST_SIDEBAR_OPEN)
    window.dispatchEvent(event)
  }

  @Watch('shoppinglist.items')
  shoppinglistChanged (): void {
    this.itemsInBag = this.shoppinglist.items.length
  }
}













import Vue from 'vue'
import Component from 'vue-class-component'
import * as fire from '../../firebaseConfig'

@Component({})
export default class Logout extends Vue {
  async logout (): Promise<void> {
    await fire.auth.signOut().then(async () => {
      await this.$router.push('/login')
    })
  }
}









































































import { userStore } from '@/auth/state'
import Vue from 'vue'
import Component from 'vue-class-component'
import { ActivityApi } from '../api/api'
import { activityFirebaseApi } from '../api/firebaseApi'
import { Activity, EventType } from '../model/activity'

@Component({})
export default class ActivityCard extends Vue {

  activityApi: ActivityApi = activityFirebaseApi

  activities: Activity[] = []

  loading = false

  async mounted (): Promise<void> {
    this.loading = true
    this.activities = await activityFirebaseApi.getLatest(5)
    this.loading = false
  }

  isCreateEvent (type: EventType ): boolean {
    return type === EventType.VICTUAL_CREATE || type === EventType.RECIPE_CREATE
  }

  isDeleteEvent (type: EventType ): boolean {
    return type === EventType.VICTUAL_DELETE || type === EventType.RECIPE_DELETE 
  }

  isUpdateEvent (type: EventType ): boolean {
    return type === EventType.VICTUAL_UPDATE || type === EventType.RECIPE_UPDATE 
  }

  showVictualAction (type: EventType): boolean {
    return type === EventType.VICTUAL_CREATE || type === EventType.VICTUAL_UPDATE
  }

  showRecipeAction (type: EventType): boolean {
    return type === EventType.RECIPE_CREATE || type === EventType.RECIPE_UPDATE
  }

  getColorFromEventType (type: EventType): string {
    if (this.isCreateEvent(type)) {
      return 'success'
    }

    if (this.isDeleteEvent(type)) {
      return 'error'
    }
    
    return 'primary'
  }

  getIconFromEventType (type: EventType): string {
    if (this.isCreateEvent(type)) {
      return 'mdi-plus'
    }

    if (this.isDeleteEvent(type)) {
      return 'mdi-delete'
    }

    return 'mdi-pencil'
  }

  resolveGroupName (groupId: string): string {
    return userStore.groupName(groupId)
  }

  getCorrectPropertyName (property: string): string {
    let transKey = ''
    switch (property) {
      case 'name': transKey = 'table.name'; break
      case 'category': transKey = 'vic.category'; break
      case 'pricePer100g': transKey = 'vic.pricePer100g'; break
      case 'description': transKey = 'rcp.description'; break
      case 'directions': transKey = 'rcp.directions'; break
      case 'ingredientGroups': transKey = 'ingredients'; break
      case 'servingSize': transKey = 'rcp.serving_size'; break
      case 'allergens': transKey = 'allergenes'; break
      case 'supermarketDepartment': transKey = 'supermarket_department'; break
      case 'units': transKey = 'units.units'; break
      case 'defaultAmountInGrams': transKey = 'units.amount_in_g_default'; break
      case 'nutriments': transKey = 'nutriments.nut'; break
    }

    const translation = this.$tc(transKey) 

    const noTranslationFound = translation === transKey
    if (noTranslationFound) {
      return this.$tc('undef') 
    }

    return translation
  }

  goToVictualDoc ( victualId: string): void {
    void this.$router.push(`/victuals/${victualId}`)
  }

  goToRecipeDoc ( recipeId: string): void {
    void this.$router.push(`/recipes/${recipeId}`)
  }

}

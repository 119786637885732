

































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import LocaleChanger from './views/LocaleChanger.vue'
import Logout from './auth/components/Logout.vue'
import ShoppinglistSidebar from './shoppinglist/ShoppinglistSidebar.vue'
import ShoppinglistButton from './shoppinglist/ShoppinglistButton.vue'

import './print.css'
import { userStore } from './auth/state'
import { Watch } from 'vue-property-decorator'

@Component({
  components: {
    LocaleChanger,
    ShoppinglistSidebar,
    ShoppinglistButton,
    Logout
  }
})
export default class App extends Vue {
  dialog = false
  drawerLeft = null
  drawerRight = null
  
  userState = userStore.state
  
  @Watch('userState', { deep: true })
  addAdminRoutes (): void {
    const feedbackReaderItem = {
      icon: 'mdi-email',
      text: 'feedback.read',
      textPlural: 1,
      route: './feedback-reader',
      isVisible: this.userState.isAdmin
    }
    this.items = this.items.filter(x => x.route !== feedbackReaderItem.route)
    this.items.push(feedbackReaderItem)
  }

  environment = process.env.NODE_ENV

  get mymVersion (): string {
    return process.env.VUE_APP_MYM_VERSION ?? ''
  }

  items: Array<{ 
    icon: string
    text: string
    textPlural: number
    route: string
    isVisible?: boolean
  }> = [
    { 
      icon: 'mdi-home',
      text: 'home',
      textPlural: 1,
      route: '/',
    },
    {
      icon: 'mdi-food-apple',
      text: 'victual',
      textPlural: 2,
      route: '/victuals',
    },
    {
      icon: 'mdi-food',
      text: 'recipe',
      textPlural: 2,
      route: '/recipes',
    },
    {
      icon: 'mdi-barcode',
      text: 'shopping_list',
      textPlural: 1,
      route: '/shoppinglist',
    },
    {
      icon: 'mdi-message-alert',
      text: 'feedback.feedback',
      textPlural: 1,
      route: '/feedback',
    },
    {
      icon: 'mdi-cog',
      text: 'config.config',
      textPlural: 1,
      route: '/config',
    }
  ]
}

import Vue from 'vue'
import firebase from 'firebase'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/auth/views/Login.vue'
import Register from '@/auth/views/Register.vue'
import { userStore } from '@/auth/state'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/agb',
    name: 'ABG',
    component: async () =>
      await import(/* webpackChunkName: "about" */ '../views/AGB.vue')
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: async () =>
      await import(/* webpackChunkName: "about" */ '../views/Impressum.vue')
  },
  {
    path: '/data-privacy',
    name: 'DataPrivacy',
    component: async () =>
      await import(/* webpackChunkName: "about" */ '../views/DataPrivacy.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/config',
    name: 'Config',
    meta: {
      requiresAuth: true
    },
    component: async () =>
      await import(/* webpackChunkName: "config" */ '@/config/views/Config.vue')
  },
  {
    path: '/victuals',
    name: 'Victuals',
    meta: {
      requiresAuth: true
    },
    component: async () =>
      await import(/* webpackChunkName: "victuals" */ '@/victual/views/Victuals.vue')
  },
  {
    path: '/victuals/new',
    name: 'Create Victual',
    meta: {
      requiresAuth: true
    },
    component: async () =>
      await import(
        /* webpackChunkName: "victuals" */ '@/victual/views/VictualCreate.vue'
      )
  },
  {
    path: '/victuals/:id/edit',
    name: 'Edit Victual',
    meta: {
      requiresAuth: true
    },
    component: async () =>
      await import(
        /* webpackChunkName: "victuals" */ '@/victual/views/VictualEdit.vue'
      )
  },
  {
    path: '/victuals/:id',
    name: 'Victual Info',
    meta: {
      requiresAuth: true
    },
    component: async () =>
      await import(
        /* webpackChunkName: "victuals" */ '@/victual/views/VictualInfo.vue'
      )
  },
  {
    path: '/recipes',
    name: 'Recipes',
    meta: {
      requiresAuth: true
    },
    component: async () =>
      await import(/* webpackChunkName: "recipes" */ '@/recipe/views/Recipes.vue')
  },
  {
    path: '/recipes/new',
    name: 'Create Recipe',
    meta: {
      requiresAuth: true
    },
    component: async () =>
      await import(
        /* webpackChunkName: "recipes" */ '@/recipe/views/RecipeCreate.vue'
      )
  },
  {
    path: '/recipes/:id/edit',
    name: 'Edit Recipes',
    meta: {
      requiresAuth: true
    },
    component: async () =>
      await import(/* webpackChunkName: "recipes" */ '@/recipe/views/RecipeEdit.vue')
  },
  {
    path: '/recipes/:id/copy',
    name: 'Copy Recipe',
    meta: {
      requiresAuth: true
    },
    component: async () =>
      await import(/* webpackChunkName: "recipes" */ '@/recipe/views/RecipeCopy.vue')
  },
  {
    path: '/recipes/:id',
    name: 'Recipe Info',
    meta: {
      requiresAuth: true
    },
    component: async () =>
      await import(/* webpackChunkName: "recipes" */ '@/recipe/views/RecipeInfo.vue')
  },
  {
    path: '/shoppinglist',
    name: 'Shoppinglist',
    meta: {
      requiresAuth: true
    },
    component: async () =>
      await import(
        /* webpackChunkName: "shoppinglists" */ '@/shoppinglist/Shoppinglist.vue'
      )
  },
  {
    path: '/shoppinglist/print',
    name: 'Print Shoppinglist',
    meta: {
      requiresAuth: true
    },
    component: async () =>
      await import(
        /* webpackChunkName: "shoppinglists" */ '@/shoppinglist/ShoppinglistPrint.vue'
      )
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: async () =>
      await import(/* webpackChunkName: "feedback" */ '@/feedback/views/FeedbackCreate.vue')
  },
  {
    path: '/feedback-reader',
    name: 'FeedbackReader',
    meta: {
      requiresAdmin: true
    },
    component: async () =>
      await import(/* webpackChunkName: "feedback" */ '@/feedback/views/FeedbackRead.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const currentUser = firebase.auth().currentUser

  if (requiresAuth && currentUser === null) {
    next('/login')
  } else if (requiresAuth && currentUser !== null && !currentUser.emailVerified) {
    next('/login')
  } else {
    next()
  }
})

router.beforeEach((to, from, next) => {
  const requiresAdmin = to.matched.some(x => x.meta.requiresAdmin)
  const isAdmin = userStore.state.isAdmin

  if (!requiresAdmin) {
    next()
  } else if (isAdmin) {
    next()
  } else {
    next('/')
  }
})

export default router
